@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  color-scheme: dark;
}

html {
  font-size: 16px;
  line-height: 16px;
}

body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: rgba(255, 255, 255, 0.87);
  /* background: #121212; */
  /* Set 5 BG */
  background: var(--primary-1);
}

/* So material ui menus don't make everything jump around */
/* @media (min-width: 900px) {
  body {
    overflow-y: scroll !important;
  }
} */

.ReactVirtualized__Grid,
.ReactVirtualized__List,
.ReactVirtualized__List:focus,
.ReactVirtualized__Grid:focus {
  outline: none;
}

.MuiTooltip-tooltip {
  font-size: 0.85rem;
}

button:focus {
  outline: none;
}

body.pupeteer .ss-hidden {
  visibility: hidden;
}

body.pupeteer .qc-cmp2-container {
  display: none;
}

body.pupeteer .ss-no-overflow {
  overflow: hidden !important;
}

.no-webkit-preview,
.no-webkit-preview > div {
  -webkit-touch-callout: "none";
  -webkit-user-select: none; /* Safari */
  user-select: none;
}

.wrapped-num {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.text-xll {
  font-size: 1.375rem;
}

.home-bookmarks {
  min-height: 312px;
}

.player-bookmarks-0 .home-bookmarks {
  min-height: 116px;
}

.player-bookmarks-1 .home-bookmarks {
  min-height: 52px;
}

.player-bookmarks-2 .home-bookmarks {
  min-height: 116px;
}

.player-bookmarks-3 .home-bookmarks {
  min-height: 116px;
}

.player-bookmarks-4 .home-bookmarks {
  min-height: 180px;
}

.player-bookmarks-5 .home-bookmarks {
  min-height: 180px;
}

.player-bookmarks-6 .home-bookmarks {
  min-height: 244px;
}

.player-bookmarks-7 .home-bookmarks {
  min-height: 244px;
}

.player-bookmarks-8 .home-bookmarks {
  min-height: 308px;
}

.player-bookmarks-9 .home-bookmarks {
  min-height: 308px;
}

@media (min-width: 900px) {
  .home-bookmarks {
    min-height: 244px;
  }

  .player-bookmarks-0 .home-bookmarks {
    min-height: 52px;
  }

  .player-bookmarks-1 .home-bookmarks {
    min-height: 52px;
  }

  .player-bookmarks-2 .home-bookmarks {
    min-height: 52px;
  }

  .player-bookmarks-3 .home-bookmarks {
    min-height: 52px;
  }

  .player-bookmarks-4 .home-bookmarks {
    min-height: 52px;
  }

  .player-bookmarks-5 .home-bookmarks {
    min-height: 116px;
  }

  .player-bookmarks-6 .home-bookmarks {
    min-height: 116px;
  }

  .player-bookmarks-7 .home-bookmarks {
    min-height: 116px;
  }

  .player-bookmarks-8 .home-bookmarks {
    min-height: 116px;
  }

  .player-bookmarks-9 .home-bookmarks {
    min-height: 180px;
  }
}

.y-scroll {
  overflow-y: auto;
}

.y-scroll::-webkit-scrollbar {
  width: 13px;
  background-color: #202022;
}

.y-scroll::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #585859;
  min-height: 24px;
  border: 3px solid #202022;
}

.y-scroll::-webkit-scrollbar-thumb:focus {
  background-color: #838384;
}

.y-scroll::-webkit-scrollbar-thumb:active {
  background-color: #838384;
}

.y-scroll::-webkit-scrollbar-corner {
  background-color: #202022;
}

.x-scroll {
  overflow-y: auto;
}

.x-scroll::-webkit-scrollbar {
  height: 11px;
  background-color: #202022;
}

.x-scroll::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #585859;
  min-height: 24px;
  border: 3px solid #202022;
}

.x-scroll::-webkit-scrollbar-thumb:focus {
  background-color: #838384;
}

.x-scroll::-webkit-scrollbar-thumb:active {
  background-color: #838384;
}

.x-scroll::-webkit-scrollbar-corner {
  background-color: #202022;
}

.report-link {
  z-index: 9 !important;
}

#na-video .brand-container {
  display: none;
}

/*
.bg-common {
  background-color: rgba(187, 187, 187, 0.2);
}

.bg-uncommon {
  background: rgba(55, 212, 136, 0.2);
}

.bg-rare {
  background: rgba(110, 204, 255, 0.2);
}

.bg-epic {
  background: rgba(220, 56, 195, 0.2);
}

.bg-legendary {
  background: rgba(241, 197, 85, 0.2);
}
*/

.player-bookmarks-0 #empty-bookmarks-placeholder:after {
  content: "Bookmark a player in player stats page for quick access";
}

@supports (content-visibility: auto) {
  .lb-season-stats .lb-ss {
    content-visibility: visible;
  }

  .lb-season-stats .lb-ps {
    content-visibility: hidden;
  }

  .lb-patch-stats .lb-ss {
    content-visibility: hidden;
  }

  .lb-patch-stats .lb-ps {
    content-visibility: visible;
  }
}

@supports not (content-visibility: auto) {
  .lb-season-stats .lb-ps {
    display: none;
  }

  .lb-patch-stats .lb-ss {
    display: none;
  }
}

@supports (content-visibility: auto) {
  .cv-auto {
    content-visibility: auto;
  }
}

@supports not (content-visibility: auto) {
  .cv-auto {
    contain: layout style paint;
  }
}

.cv-hidden {
  content-visibility: hidden;
}

.cv-visible {
  content-visibility: visible;
}

.player-matches {
  contain-intrinsic-size: 0 4500px;
}

.player-expandable-match {
  min-height: 188px;
  contain-intrinsic-size: 0 188px;
}

@media (min-width: 640px) {
  .player-expandable-match {
    min-height: 169px;
    contain-intrinsic-size: 0 169px;
  }
}

@media (min-width: 910px) {
  .player-expandable-match {
    min-height: 111px;
    contain-intrinsic-size: 0 111px;
  }
}

.tc-summary {
  contain-intrinsic-size: 0 279px;
}

@media (min-width: 640px) {
  .tc-summary {
    contain-intrinsic-size: 0 316px;
  }
}

@media (min-width: 1280px) {
  .tc-summary {
    contain-intrinsic-size: 0 171px;
  }
}

.text-shadow {
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
}

.text-shadow-strong {
  text-shadow: -1px 0 2px #000, 0 1px 2px #000, 1px 0 2px #000, 0 -1px 2px #000;
}

.top-trends {
  animation-duration: 40s;
  animation-name: trends-scroll;
  animation-iteration-count: infinite;
  animation-direction: forward;
  animation-timing-function: linear;
}

.top-trends-wrap:hover .top-trends {
  animation-play-state: paused;
}

.top-trends-wrap:hover .top-trends-overlay {
  display: flex;
}

.top-trends-overlay {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    hsl(213, 71.2%, 20.2%) 36% 100%
  );
}

@keyframes trends-scroll {
  from {
    transform: translate(0px);
  }

  to {
    transform: translate(-4140px);
  }
}

.tc-summary-wrap {
  transition: background 0.3s, box-shadow 0.3s;
}

.tc-summary-wrap:hover {
  background: #cccccc22;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mantine-Modal-inner {
  overflow-y: scroll;
}

@media (min-width: 910px) {
  .mantine-Modal-inner {
    padding-left: 47px;
  }
}

.support-aug-marker {
  position: absolute;
  bottom: -1px;
  right: -1px;
  width: 12px;
  height: 12px;
  border-bottom: 1px solid var(--primary-2);
  border-right: 1px solid var(--primary-2);
  background: linear-gradient(
    135deg,
    transparent 0%,
    transparent 46%,
    var(--primary-2) 46%,
    var(--primary-2) 54%,
    var(--green-10) 54%,
    var(--green-10) 100%
  );
}

.carry-aug-marker {
  position: absolute;
  bottom: -1px;
  left: -1px;
  width: 12px;
  height: 12px;
  border-bottom: 1px solid var(--primary-2);
  border-left: 1px solid var(--primary-2);
  background: linear-gradient(
    225deg,
    transparent 0%,
    transparent 46%,
    var(--primary-2) 46%,
    var(--primary-2) 54%,
    var(--secondary-10) 54%,
    var(--secondary-10) 100%
  );
}

.support-aug-marker-lg {
  position: absolute;
  bottom: -1px;
  right: -1px;
  width: 16px;
  height: 16px;
  border-bottom: 1px solid var(--primary-2);
  border-right: 1px solid var(--primary-2);
  background: linear-gradient(
    135deg,
    transparent 0%,
    transparent 46.75%,
    var(--primary-2) 46.75%,
    var(--primary-2) 53.25%,
    var(--green-10) 53.5%,
    var(--green-10) 100%
  );
}

.carry-aug-marker-lg {
  position: absolute;
  bottom: -1px;
  left: -1px;
  width: 16px;
  height: 16px;
  border-bottom: 1px solid var(--primary-2);
  border-left: 1px solid var(--primary-2);
  background: linear-gradient(
    225deg,
    transparent 0%,
    transparent 46.5%,
    var(--primary-2) 46.75%,
    var(--primary-2) 53.25%,
    var(--secondary-10) 53.25%,
    var(--secondary-10) 100%
  );
}

.Mui-disabled {
  color: var(--white3) !important;
}

.pl-search-bg {
  background: var(--primary-6);
}
body.midnight .pl-search-bg {
  background: var(--primary-4);
}

.triangle-clip {
  clip-path: polygon(0 0, 100% 0, 0 100%);
}

.border-1-aa {
  box-shadow: 0 0 1px 0px var(--white2) inset, 0 0 1px 0px var(--white2);
}

.icon-Bilgewater {
  mask: url(https://ap.tft.tools/static/trait-icons/Set9b_Bilgewater_w.svg);
  background: var(--tomato-9);
}

.icon-Ixtal {
  mask: url(https://ap.tft.tools/static/trait-icons/Set9_Ixtal_w.svg);
  background: var(--grass-9);
}

.icon-Shurima {
  mask: url(https://ap.tft.tools/static/trait-icons/Set9_Shurima_w.svg);
  background: var(--amber-9);
}

.icon-ShadowIsles {
  mask: url(https://ap.tft.tools/static/trait-icons/Set9_ShadowIsles_w.svg);
  background: var(--teal-9);
}

.icon-Zaun {
  mask: url(https://ap.tft.tools/static/trait-icons/Set9_Zaun_w.svg);
  background: var(--green-9);
}

.icon-Noxus {
  mask: url(https://ap.tft.tools/static/trait-icons/Set9_Noxus_w.svg);
  background: var(--ruby-9);
}

.icon-Ionia {
  mask: url(https://ap.tft.tools/static/trait-icons/Set9_Ionia_w.svg);
  background: var(--pink-9);
}

.icon-Freljord {
  mask: url(https://ap.tft.tools/static/trait-icons/Set9_Freljord_w.svg);
  background: var(--cyan-9);
}

.icon-Targon {
  mask: url(https://ap.tft.tools/static/trait-icons/Set9_Targon_w.svg);
  background: var(--iris-10);
}

.icon-Demacia {
  mask: url(https://ap.tft.tools/static/trait-icons/Set9_Demacia_w.svg);
  background: var(--yellow-11);
}

.icon-Void {
  mask: url(https://ap.tft.tools/static/trait-icons/Set9_Void_w.svg);
  background: var(--purple-10);
}

.icon-Piltover {
  mask: url(https://ap.tft.tools/static/trait-icons/Set9_Piltover_w.svg);
  background: var(--orange-9);
}

.icon-BandleCity {
  mask: url(https://ap.tft.tools/static/trait-icons/Set9_BandleCity2_w.svg);
  background: var(--lime-9);
}

.anomaly-img {
  overflow-wrap: break-word;
}
