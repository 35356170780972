:root {
  --common: #bbbbbb;
  --uncommon: #14cc73;
  --rare: #54c3ff;
  --epic: #de0ebd;
  --legendary: #ffc430;

  --common-2: #bbbbbb;
  --uncommon-2: #37d488;
  --rare-2: #6eccff;
  --epic-2: #dc38c3;
  --legendary-2: #f1c555;

  --common-3: #bbbbbb;
  --uncommon-3: #5bdb9d;
  --rare-3: #87d5ff;
  --epic-3: #e856d1;
  --legendary-3: #ffd66e;

  --primary-1: hsl(212, 35%, 9.2%);
  --primary-2: hsl(216, 50%, 11.8%);
  --primary-3: hsl(214, 59.4%, 15.3%);
  --primary-4: hsl(214, 65.8%, 17.9%);
  --primary-5: hsl(213, 71.2%, 20.2%);
  --primary-6: hsl(212, 77.4%, 23.1%);
  --primary-7: hsl(211, 85.1%, 27.4%);
  --primary-8: hsl(211, 89.7%, 34.1%);
  --primary-9: hsl(206, 100%, 50%);
  --primary-10: hsl(209, 100%, 60.6%);
  --primary-11: hsl(210, 100%, 66.1%);
  --primary-12: hsl(206, 98%, 95.8%);
  --primary-1a: hsla(0, 0%, 0%, 0);
  --primary-2a: hsla(221, 97.8%, 52.4%, 0.059);
  --primary-3a: hsla(215, 99.3%, 54.2%, 0.135);
  --primary-3a2: hsla(215, 99.3%, 54.2%, 0.07);
  --primary-3a3: hsla(215, 99.3%, 54.2%, 0.09);
  --primary-4a: hsla(215, 99.3%, 53.8%, 0.198);
  --primary-4a2: hsla(214, 70.8%, 17.9%, 90%);
  --primary-5a: hsla(213, 99.4%, 52.8%, 0.252);
  --primary-5a2: hsla(213, 71.2%, 19.2%, 90%);
  --primary-6a: hsla(212, 99.9%, 51.7%, 0.323);
  --primary-7a: hsla(211, 100%, 50.7%, 0.435);
  --primary-8a: hsla(211, 99.8%, 50.9%, 0.597);
  --primary-9a: hsla(205, 100%, 50%, 0.98);
  --primary-10a: hsla(208, 100%, 60.7%, 0.98);
  --primary-11a: hsla(209, 100%, 66.3%, 0.98);
  --primary-12a: hsla(196, 100%, 96.8%, 0.98);

  --secondary-1: hsl(250, 20%, 10.2%);
  --secondary-2: hsl(255, 30.3%, 12.9%);
  --secondary-3: hsl(253, 37%, 18.4%);
  --secondary-4: hsl(252, 40.1%, 22.5%);
  --secondary-5: hsl(252, 42.2%, 26.2%);
  --secondary-6: hsl(251, 44.3%, 31.1%);
  --secondary-7: hsl(250, 46.8%, 38.9%);
  --secondary-8: hsl(250, 51.8%, 51.2%);
  --secondary-9: hsl(252, 56%, 57.5%);
  --secondary-10: hsl(251, 63.2%, 63.2%);
  --secondary-11: hsl(250, 95%, 76.8%);
  --secondary-12: hsl(252, 87%, 96.4%);
  --secondary-1a: hsla(0, 0%, 0%, 0);
  --secondary-2a: hsla(258, 98.2%, 61%, 0.054);
  --secondary-3a: hsla(252, 98.8%, 65.8%, 0.148);
  --secondary-4a: hsla(253, 99.7%, 65.7%, 0.219);
  --secondary-5a: hsla(252, 99.7%, 66.4%, 0.286);
  --secondary-6a: hsla(251, 99.7%, 66.2%, 0.371);
  --secondary-7a: hsla(250, 99.7%, 66.3%, 0.514);
  --secondary-8a: hsla(250, 99.7%, 66.1%, 0.733);
  --secondary-9a: hsla(252, 99.9%, 70.3%, 0.786);
  --secondary-10a: hsla(251, 99.9%, 72.9%, 0.844);
  --secondary-11a: hsla(250, 100%, 77.9%, 0.98);
  --secondary-12a: hsla(254, 100%, 97.5%, 0.98);

  --tertiary-1: hsl(168, 48%, 6.5%);
  --tertiary-2: hsl(169, 77.8%, 7.1%);
  --tertiary-3: hsl(170, 76.1%, 9.2%);
  --tertiary-4: hsl(171, 75.8%, 11%);
  --tertiary-5: hsl(171, 75.7%, 12.8%);
  --tertiary-6: hsl(172, 75.8%, 15.1%);
  --tertiary-7: hsl(172, 76.7%, 18.6%);
  --tertiary-8: hsl(173, 80.2%, 23.7%);
  --tertiary-9: hsl(173, 80%, 36%);
  --tertiary-10: hsl(174, 83.9%, 38.2%);
  --tertiary-11: hsl(174, 90%, 40.7%);
  --tertiary-12: hsl(166, 73%, 93.1%);
  --tertiary-1a: hsla(0, 0%, 0%, 0);
  --tertiary-2a: hsla(171, 100%, 49.2%, 0.031);
  --tertiary-3a: hsla(172, 100%, 49.7%, 0.07);
  --tertiary-4a: hsla(175, 100%, 49.7%, 0.105);
  --tertiary-5a: hsla(174, 98.9%, 50.1%, 0.14);
  --tertiary-6a: hsla(174, 100%, 51.8%, 0.187);
  --tertiary-7a: hsla(173, 99.6%, 53.2%, 0.257);
  --tertiary-8a: hsla(174, 99.6%, 53.3%, 0.366);
  --tertiary-9a: hsla(173, 99.9%, 54.6%, 0.609);
  --tertiary-10a: hsla(174, 99.9%, 53.8%, 0.67);
  --tertiary-11a: hsla(174, 100%, 52%, 0.748);
  --tertiary-12a: hsla(166, 98.6%, 95%, 0.979);

  --red-8: #aa2429;
  --red-9: #e5484d;
  --red-10: #f2555a;
  --red-11: #ff6369;
  --green-8: #236e4a;
  --green-9: #30a46c;
  --green-10: #3cb179;
  --green-11: #4cc38a;
  --blue-8: #0954a5;
  --blue-9: #0091ff;
  --amber-9: #ffb224;
  --tomato-9: #e54d2e;
  --tomato-10: #ec5e41;
  --tomato-11: #f16a50;
  --amber-11: #f1a10d;
  --yellow-9: #fbe32d;
  --yellow-11: #f0c000;
  --brown-11: #dba16e;
  --bronze-11: #cba393;
  --gold-11: #bfa888;
  --sky-11: #2ec8ee;
  --cyan-9: #05a2c2;
  --cyan-11: #00c2d7;
  --orange-9: #f76808;

  --teal-9: #12a594;
  --teal-11: #0ac5b3;

  --lime-9: #bdee63;
  --iris-9: #5b5bd6;
  --iris-10: #6f6de2;
  --pink-9: #d6409f;
  --indigo-10: #5c73e7;
  --purple-9: #8e4ec6;
  --purple-10: #9e63d5;
  --ruby-8: #d01b3f;
  --ruby-9: #e54666;
  --grass-9: #46a758;

  color: var(--primary-12);

  --white1: rgb(234, 246, 255);
  --white2: rgba(234, 246, 255, 0.7);
  --white3: rgba(234, 246, 255, 0.45);
}

#app-bg {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* background: url("/bg_s70.png") center center / cover no-repeat; */
  /* background: url("https://cdn.tft.tools/general/bg_s70.png?quality=90") center */

  /* background: url("https://ap.tft.tools/img/bg/s85_dark2.jpg?w=960") center
    center / cover no-repeat; */
  /* background: url("https://ap.tft.tools/img/bg/s9_dark_2.jpg?w=960") center
    center / cover no-repeat; */
  background: url("https://ap.tft.tools/img/bg/s11_dark8.jpg?w=1920") center
    center / cover no-repeat;
  /*
  background: url("https://cdn.tft.tools/general/bg_s8_dark.jpg?quality=90")
    center center / cover no-repeat; */
}

body.midnight #app-bg {
  /* background: url("https://ap.tft.tools/img/bg/s85_midnight2.jpg?w=960") center
    center / cover no-repeat; */
  /* background: url("https://ap.tft.tools/img/bg/s9_midnight_2.jpg?w=960") center
    center / cover no-repeat; */
  background: url("https://ap.tft.tools/img/bg/s11_midnight11.jpg?w=1920") center
    center / cover no-repeat;
}
body.midnight #app-bg.s11 {
}

body.light #app-bg {
  /* background: url("https://cdn.tft.tools/general/bg_s8_light.jpg?quality=90")
    center center / cover no-repeat; */
  background: url("https://ap.tft.tools/img/bg/s9_light.jpg?w=960") center
    center / cover no-repeat;
}

body.light {
  --common: #505050bb;
  --uncommon: #11b288;
  --rare: #207ac7;
  --epic: #c440da;
  --legendary: #ffb93b;

  --primary-5b: hsla(208, 95.1%, 85.1%, 0.75);
  --primary-1: hsl(206, 100%, 99.2%);
  --primary-2: hsl(210, 100%, 98%);
  --primary-3: hsl(209, 100%, 96.5%);
  --primary-4: hsl(210, 98.8%, 94%);
  --primary-5: hsl(209, 95%, 90.1%);
  --primary-6: hsl(209, 81.2%, 84.5%);
  --primary-7: hsl(208, 77.5%, 76.9%);
  --primary-8: hsl(206, 81.9%, 65.3%);
  --primary-9: hsl(206, 100%, 50%);
  --primary-10: hsl(208, 100%, 47.3%);
  --primary-11: hsl(211, 100%, 43.2%);
  --primary-12: hsl(211, 100%, 15%);
  --primary-1a: hsla(210, 100%, 51%, 0.016);
  --primary-2a: hsla(210, 100%, 51%, 0.04);
  --primary-3a: hsla(210, 100%, 50.3%, 0.071);
  --primary-4a: hsla(210, 100%, 50.1%, 0.118);
  --primary-5a: hsla(208, 99.1%, 47.1%, 0.189);
  --primary-6a: hsla(209, 99.5%, 45.3%, 0.283);
  --primary-7a: hsla(208, 99.9%, 43.8%, 0.412);
  --primary-8a: hsla(206, 99.8%, 45.1%, 0.632);
  --primary-9a: hsla(206, 100%, 50%, 0.98);
  --primary-10a: hsla(208, 100%, 47.2%, 0.98);
  --primary-11a: hsla(212, 100%, 43%, 0.98);
  --primary-12a: hsla(213, 100%, 14.4%, 0.98);

  --secondary-1: hsl(255, 65%, 99.4%);
  --secondary-2: hsl(252, 100%, 99%);
  --secondary-3: hsl(252, 96.9%, 97.4%);
  --secondary-4: hsl(252, 91.5%, 95.5%);
  --secondary-5: hsl(252, 85.1%, 93%);
  --secondary-6: hsl(252, 77.8%, 89.4%);
  --secondary-7: hsl(252, 71%, 83.7%);
  --secondary-8: hsl(252, 68.6%, 76.3%);
  --secondary-9: hsl(252, 56%, 57.5%);
  --secondary-10: hsl(251, 48.1%, 53.5%);
  --secondary-11: hsl(250, 43%, 48%);
  --secondary-12: hsl(254, 60%, 18.5%);
  --secondary-1a: hsla(270, 94.3%, 34.6%, 0.012);
  --secondary-2a: hsla(252, 100%, 51%, 0.02);
  --secondary-3a: hsla(254, 100%, 50%, 0.051);
  --secondary-4a: hsla(251, 98.3%, 48.2%, 0.087);
  --secondary-5a: hsla(252, 99%, 45.7%, 0.13);
  --secondary-6a: hsla(251, 99.1%, 44%, 0.189);
  --secondary-7a: hsla(252, 99.5%, 41.7%, 0.279);
  --secondary-8a: hsla(252, 100%, 40.7%, 0.4);
  --secondary-9a: hsla(252, 99.9%, 35.8%, 0.663);
  --secondary-10a: hsla(251, 99.6%, 32.5%, 0.691);
  --secondary-11a: hsla(250, 99.8%, 28.4%, 0.726);
  --secondary-12a: hsla(254, 99.5%, 11.9%, 0.926);

  --tertiary-1: hsl(165, 60%, 98.8%);
  --tertiary-2: hsl(169, 64.7%, 96.7%);
  --tertiary-3: hsl(169, 59.8%, 94%);
  --tertiary-4: hsl(169, 53.1%, 90.2%);
  --tertiary-5: hsl(170, 47.1%, 85%);
  --tertiary-6: hsl(170, 42.6%, 77.9%);
  --tertiary-7: hsl(170, 39.9%, 68.1%);
  --tertiary-8: hsl(172, 42.1%, 52.5%);
  --tertiary-9: hsl(173, 80%, 36%);
  --tertiary-10: hsl(173, 83.4%, 32.5%);
  --tertiary-11: hsl(174, 90%, 25.2%);
  --tertiary-12: hsl(170, 50%, 12.5%);
  --tertiary-1a: hsla(165, 95.2%, 41.2%, 0.02);
  --tertiary-2a: hsla(169, 99.5%, 39.4%, 0.055);
  --tertiary-3a: hsla(167, 97.6%, 38.1%, 0.095);
  --tertiary-4a: hsla(168, 98.1%, 34.6%, 0.15);
  --tertiary-5a: hsla(170, 99.4%, 32.3%, 0.22);
  --tertiary-6a: hsla(170, 99.7%, 30.1%, 0.314);
  --tertiary-7a: hsla(170, 99.3%, 28.7%, 0.448);
  --tertiary-8a: hsla(172, 99.8%, 29.7%, 0.675);
  --tertiary-9a: hsla(173, 99.8%, 31.1%, 0.93);
  --tertiary-10a: hsla(173, 99.7%, 28.7%, 0.946);
  --tertiary-11a: hsla(174, 99.8%, 23.3%, 0.977);
  --tertiary-12a: hsla(171, 98.8%, 6.8%, 0.938);

  --red-9: #e5484d;
  --red-10: #f2555a;
  --red-11: #ff6369;
  --green-9: #30a46c;
  --green-10: #3cb179;
  --green-11: #4cc38a;
  --blue-9: #0091ff;

  --teal-11: #0ac5b3;
  --amber-9: #ffb224;
  --amber-9a: #ffa600db;
  --amber-10: #ffa01c;
  --amber-10a: #ff9500e3;
  --yellow-9: #f5d90a;
  --yellow-9a: #f5d800f5;
  --yellow-10: #f7ce00;
  --yellow-10a: #f7ce00fa;

  --green-10: #299764;
  --purple-10: #8445bc;

  --white1: rgb(0, 37, 77);
  --white2: rgba(0, 37, 77, 0.7);
  --white3: rgba(0, 37, 77, 0.45);

  color: var(--white1);

  --red-9: #e5484d;
  --green-9: #30a46c;
  --blue-9: #0091ff;
}

/* .title-bar {
  position: fixed;
  top: 0;
  -webkit-user-select: none;
  -webkit-app-region: drag;
  z-index: 1;
  width: 100vw;
  height: 40px;
  background: var(--primary-3a2);
  cursor: grab;
  backdrop-filter: blur(2px);
} */

.bg-bg0 {
  background: var(--primary-5);
}
body.midnight .bg-bg0 {
  background: var(--primary-2);
}
body.light .bg-bg0 {
  background: var(--primary-4);
}

.bg-bg {
  background: var(--primary-5a2);
  backdrop-filter: blur(1px);
  transform: translate3d(0, 0, 0);
}
body.midnight .bg-bg {
  background: var(--primary-2);
  backdrop-filter: none;
}
body.light .bg-bg {
  background: var(--primary-4);
}

.bg-bg2 {
  background: rgba(255, 255, 255, 0.05);
}
body.midnight .bg-bg2 {
  background: var(--primary-3a2);
}
body.light .bg-bg2 {
  background: var(--primary-3a);
}

.bg-bg2-hover:hover {
  background: rgba(255, 255, 255, 0.05);
}
body.midnight .bg-bg2-hover:hover {
  background: var(--primary-3a2);
}
body.light .bg-bg2-hover:hover {
  background: var(--primary-3a);
}

.bg-bg3 {
  background: rgba(255, 255, 255, 0.08);
}
body.midnight .bg-bg3 {
  background: var(--primary-3a3);
}

.elevation-2 {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.elevation-4 {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.elevation-6 {
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px,
    rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0px;
}

.elevation-8 {
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}

.elevation-8 {
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}

.elevation-12 {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 7px 8px -4px, rgba(0, 0, 0, 0.14) 0px 12px 17px 2px, rgba(0, 0, 0, 0.12) 0px 5px 22px 4px;
}

.elevation-16 {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 10px -5px, rgba(0, 0, 0, 0.14) 0px 16px 24px 2px, rgba(0, 0, 0, 0.12) 0px 6px 30px 5px;
}

.elevation-24 {
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
}

.how-to-icon {
  transition: all 300ms;
  transform: rotate(0deg);
}

.how-to-icon.active {
  transform: rotate(180deg);
}

.how-to-content {
  transition: margin-top 350ms;
  margin-top: -100%;
}

.how-to-content.active {
  margin-top: 0;
}

.h1 {
  font-size: 1.875rem;
  font-weight: 500;
  font-family: "Montserrat";
}

.h2 {
  font-size: 1.5rem;
  font-weight: 500;
  font-family: "Montserrat";
}

.h3 {
  font-size: 1.25rem;
  font-weight: 500;
  font-family: "Montserrat";
}

.h4 {
  font-size: 1.125rem;
  font-weight: 500;
  font-family: "Montserrat";
}

.truncate-2 {
  width: 52px;
  margin: 0 -2px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  overflow-wrap: break-word;
}
