.main-nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  height: 48px;
  min-width: 52px;

  transition: background-color 0.2s ease;
  letter-spacing: 0.07px;
}

.main-nav-link-active {
  font-weight: 600;
  letter-spacing: 0;
  background: var(--secondary-7);
}
body.midnight .main-nav-link-active {
  font-weight: 600;
  letter-spacing: 0;
  background: var(--secondary-5);
}

.main-nav-link-wrap {
  position: relative;
}

.main-nav-link-wrap:hover .main-nav-link-subheader {
  pointer-events: auto;
  max-height: 340px;
}

.main-nav-link-wrap:hover .main-nav-link {
  background: var(--secondary-7);
}
body.midnight .main-nav-link-wrap:hover .main-nav-link {
  background: var(--secondary-5);
}

.main-nav-link-subheader {
  /* display: none; */
  pointer-events: none;
  overflow: hidden;
  overflow: clip;
  position: absolute;
  z-index: 30;
  width: auto;
  height: auto;
  top: 48px;
  left: 0;
  max-height: 0px;
  font-size: 14px;

  background: var(--secondary-6);
  transition: max-height 0.2s ease;
}
body.midnight .main-nav-link-subheader {
  background: var(--secondary-3);
}

.bg-secondary-nav {
  background: var(--secondary-6);
}
body.midnight .bg-secondary-nav {
  background: var(--secondary-3);
}

.subnav-link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 100%;
  padding: 0 20px;
  padding-bottom: 2px;
  white-space: nowrap;
  background: var(--secondary-6);
  transition: background-color 0.15s ease;
}
body.midnight .subnav-link {
  background: var(--secondary-3);
}

.subnav-link:first-child {
  padding-top: 4px;
  height: 46px;
}

.subnav-link:last-child {
  padding-bottom: 6px;
  height: 46px;
}

.subnav-link span {
  position: relative;
}

.subnav-link span:before {
  position: absolute;
  transition: width 0.2s ease;
}
.subnav-link span:before {
  bottom: -4px;
  left: -5px;
  display: block;
  height: 2px;
  width: 0%;
  content: "";
  background-color: var(--secondary-11);
}
body.midnight .subnav-link span:before {
  background-color: var(--secondary-9);
}

.subnav-link:hover span:before {
  opacity: 1;
  width: calc(100% + 10px);
}

.subnav-link:hover {
  background: var(--secondary-7);
}
body.midnight .subnav-link:hover {
  background: var(--secondary-5);
}

.secondary-nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  height: 44px;
  min-width: 80px;

  transition: background-color 0.15s ease;
  letter-spacing: 0.06px;
}

.secondary-nav-link.active,
.secondary-nav-link:hover {
  background: var(--secondary-7);
}
body.midnight .secondary-nav-link.active,
body.midnight .secondary-nav-link:hover {
  background: var(--secondary-5);
}

.secondary-nav-link span {
  position: relative;
}

.secondary-nav-link span.active {
  font-weight: 600;
  letter-spacing: 0;
}

.secondary-nav-link span:before {
  position: absolute;
  transition: width 0.2s ease;
}
.secondary-nav-link span:before {
  bottom: -4px;
  left: -5px;
  display: block;
  height: 2px;
  width: 0%;
  content: "";
  background-color: var(--secondary-11);
}
body.midnight .secondary-nav-link span:before {
  background-color: var(--secondary-9);
}

.secondary-nav-link:hover span:before,
.secondary-nav-link span.active:before {
  opacity: 1;
  width: calc(100% + 10px);
}

.drawer-nav-link {
  width: 100%;
  padding: 0 20px;
  display: flex;
  height: 48px;
  align-items: center;
  /* justify-content: center;
  text-align: center; */
  transition: background-color 0.2s ease;
  cursor: pointer;
  user-select: none;
  line-height: 1.2;
  letter-spacing: 0.07px;
}

.drawer-nav-link.active {
  background: var(--secondary-7);
  font-weight: 600;
  letter-spacing: 0;
}
body.midnight .drawer-nav-link.active {
  background: var(--secondary-5);
}

.drawer-nav-link:hover {
  background: var(--secondary-7);
}
body.midnight .drawer-nav-link:hover {
  background: var(--secondary-5);
}

.side-nav-primary {
  width: 180px;
  position: absolute;
  top: 16px;
  transition: all 0.2s ease;
}

.side-nav-primary-active {
  left: 0px;
  opacity: 1;
}
.side-nav-primary-hidden {
  left: -32px;
  opacity: 0;
  pointer-events: none;
}

.side-nav-secondary {
  width: 180px;
  position: absolute;
  top: 16px;
  transition: all 0.25s ease;
}

.side-nav-secondary-active {
  opacity: 1;
  left: 0px;
}
.side-nav-secondary-hidden {
  opacity: 0;
  left: 32px;
  pointer-events: none;
}
